/* 
pure-react-carousel exposes 
BEM formatted class names to style its component parts.

ImageWithZoom creates two Image components using divs rather than imgs.
This then uses the div's background-size rather than the img's object-fit 
to control the resizing of the image.
When using div you also need background-position set to center to get the
same behaviour as object-fit

*/
.carousel__image--with-background {
  background-position: center;
}

.carousel__dot {
  border-radius: 50%;
  background: transparent no-repeat;
  border: 2px solid white;
  outline: none;
  height: 15px;
  width: 15px;
}

.carousel__dot--selected {
  border-radius: 50%;
  background: white;
  border: 2px solid white;
  outline: none;
  height: 17px;
  width: 17px;
}
