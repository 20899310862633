@import-normalize;

body {
  margin: 0;
  padding: 0;
}

/* remove default link styling */
a {
  text-decoration: none;
  color: black;
}
